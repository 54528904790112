import React from "react";
import Icon from "atoms/Icon";
import { ReadyState } from "react-use-websocket";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import crm from "assets/productlogo/crm.svg";
import tymeplus from "assets/productlogo/tymeplus.svg";
import tractask from "assets/productlogo/tractask.svg";
import myaccount from "assets/productlogo/myaccount.svg";

const ORIGIN = window.location.origin;

const LIVE_SOCKET = `wss://aaisnlrjy7.execute-api.us-east-1.amazonaws.com/Prod/`;
const TEST_SOCKET = `wss://aaisnlrjy7.execute-api.us-east-1.amazonaws.com/Prod/`;
const DEV_SOCKET = `wss://aaisnlrjy7.execute-api.us-east-1.amazonaws.com/Prod/`;
export const SERVICE_URL = `https://gmer55i7g3.execute-api.us-east-1.amazonaws.com/Prod/`;
export const AI_BASE_URL = `https://mvff9fjrsc.execute-api.ap-south-1.amazonaws.com/dev/`;
export const LOGIN_BASE_URL = `https://gmer55i7g3.execute-api.us-east-1.amazonaws.com/Prod/`;

export const AwardList = [
  "Select",
  "Accounting and Auditing Board of Ethiopia",
  "Association of Chartered Certified Accountants",
  "Cisco Certified Network Associate",
  "Certified Ethical Hacker",
  "Chartered Financial Analyst",
  "Chartered Financial Analyst Institute",
  "Certified Human Resource Professional",
  "Certified Investment & Financial Analyst",
  "Chartered Institute of Marketing",
  "Chartered Institute of Procurement & Supply",
  "Certified Information Systems Auditor",
  "Computer Industry and Services Association of Kenya",
  "Certified Information Systems Manager",
  "Chartered Marketing Management Professional",
  "Certified Public Accountant",
  "Certified Public Secretary",
  "Certified Procurement and Supply Professional – Kenya",
  "Institute of Certified Investment & Financial Analysts",
  "Institute of Certified Public Accountants of Kenya",
  "Institute of Certified Public Accountants of Rwanda",
  "Institute of Certified Public Accountants of Uganda",
  "Institute of Certified Secretaries of Kenya",
  "Institute of Directors",
  "Institute of Human Resource Management",
  "Institute of Internal Auditors",
  "Information Systems Audit and Control Association",
  "Microsoft Certified AI Engineer",
  "Microsoft Certified Data Engineer",
  "Microsoft Certified DevOps Engineer",
  "Microsoft Certified Data Scientist",
  "Microsoft Certified Functional Consultant",
  "Microsoft Certified Systems Administrator",
  "Microsoft Certified Solutions Architect",
  "Microsoft Certified Systems Developer",
  "Microsoft Certified Security Engineer",
  "Marketing Society of Kenya",
  "National Board of Accountants and Auditors – Tanzania",
  "Project Management Institute",
  "Project Management Professional",
];

export const SOCKET_URL =
  ORIGIN.indexOf("localhost") > -1
    ? TEST_SOCKET
    : ORIGIN.indexOf("test") > -1
    ? TEST_SOCKET
    : LIVE_SOCKET;

export const Products = [
  {
    name: "Tymeplus",
    url: "",
    logo: tymeplus,
    access: true,
  },
  {
    name: "TracTask",
    url: `https://www.tractask.waila.co/?token=${window.localStorage.getItem(
      "token"
    )}`,
    logo: tractask,
    access: true,
  },
  {
    name: "Marketing Tool",
    url: `https://www.crm.waila.co/?token=${window.localStorage.getItem(
      "token"
    )}`,
    logo: crm,
    access: true,
  },
  {
    name: "My Account",
    url: `https://www.myaccount.waila.co/?token=${window.localStorage.getItem(
      "token"
    )}`,
    logo: myaccount,
    access: true,
  },
];

export const NON_EMPTY_REGEX = /^(?!\s*$).+/;

export const EMAIL_REGEX = /^[a-zA-Z0-9.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const NON_EMPTY_SINGLE_NUMBER = /\b[0-9]\b/;

export const NON_EMPTY_NUMBER = /^\d+$/;

export const NON_CHARACTER = /^[0-9\.\-\+]+$/;

//Validate symbol except space
export const VALIDATE_SYMBOL = /^[^<>*|/\\~#{}()[\],$^%&+=_@'?.!"]+$/g;

export const VALIDATE_CHARACTER = /^[A-Za-z\s]+$/;

export const VALIDATE_SYMBOL_CHARACTER =
  /^[a-zA-Z\s~`!@#$%^&*()_+\-=\[\]{}|;':",.\/<>?\\]*$/;

export const PUNCH_ACTIONS = {
  PUNCH_IN: 1,
  PUNCH_OUT: 2,
  BREAK_START: 3,
  BREAK_END: 4,
};

export const InitalSteps = {
  title: "",
  steps: [{ 0: "New" }],
};

export const STATUS = {
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
  ERROR: "ERROR",
  RECORD_NOT_FOUND: "RECORD_NOT_FOUND",
};

const dashboardMenu = {
  key: "dashboard",
  icon: <Icon name="dashboard" />,
  path: "dashboard",
  title: "Dashboard",
};

const adminDashboardMenu = {
  key: "dashboard",
  icon: <Icon name="dashboard" />,
  title: "Dashboard",
  subMenu: [
    {
      key: "dashboard",
      path: "dashboard",
      title: "Dashboard",
      icon: <Icon name="subdashboard" />,
    },
    {
      key: "trends",
      path: "trends",
      title: "Trends",
      icon: <Icon name="trends" />,
    },
    {
      key: "demographics",
      path: "demographics",
      title: "Demographics",
      icon: <Icon name="demographics" />,
    },
    {
      key: "productivity",
      path: "productivity",
      title: "Productivity",
      icon: <SettingsApplicationsIcon style={{ fontSize: "20px" }} />,
    },
  ],
};

const approvalMenu = {
  key: "approvals",
  icon: <Icon name="approvals" />,
  path: "approvals",
  title: "Approvals",
};

const calendarMenu = {
  key: "calendar",
  icon: <Icon name="calendar" />,
  path: "calendar",
  title: "Calendar",
};

const locationMenu = {
  key: "location",
  icon: <Icon name="location" />,
  path: "location",
  title: "Location",
};

const myCalendarMenu = {
  key: "calendar",
  icon: <Icon name="calendar" />,
  path: "calendar",
  title: "My Calendar",
};

const teamMenu = {
  key: "team",
  icon: <Icon name="team" />,
  path: "team",
  title: "Teams",
};

const myTeamMenu = {
  key: "team",
  icon: <Icon name="team" />,
  path: "team",
  title: "My Team",
};

const myDocumentMenu = {
  key: "mydocuments",
  icon: <Icon name="document" />,
  path: "mydocuments",
  title: "My Documents",
};

const taskMenu = {
  key: "task",
  icon: <Icon name="task" />,
  path: "task",
  title: "Task",
};

const myTaskMenu = {
  key: "mytask",
  icon: <Icon name="task" />,
  path: "mytask",
  title: "My Task",
};

const myLeaveReport = {
  key: "leavereport",
  icon: <Icon name="leave" />,
  path: "myleavereport",
  title: "My Leave History",
};

const reportMenu = {
  key: "reports",
  icon: <Icon name="report" />,
  title: "Reports",
  subMenu: [
    {
      key: "attendance-report",
      title: "Attendance",
      icon: <Icon name="attendance" />,
      subMenu: [
        {
          key: "attendance-report-daily",
          path: "attendance-report",
          title: "Daily",
          icon: <DoneAllIcon style={{ fontSize: "20px" }} />,
        },
        {
          key: "attendance-report-monthly",
          path: "attendance-report-monthly",
          title: "Monthly",
          icon: <CalendarMonthIcon style={{ fontSize: "20px" }} />,
        },
      ],
    },
    {
      key: "leave-report",
      path: "leave-report",
      title: "Leave",
      icon: <Icon name="leave" />,
    },
    {
      key: "break-report",
      path: "break-report",
      title: "Break",
      icon: <Icon name="break" />,
    },
    {
      key: "overtime-report",
      path: "overtime-report",
      title: "Overtime",
      icon: <Icon name="overtime" />,
    },
  ],
};

const ExceptionReport = {
  key: "Exception Reports",
  icon: <Icon name="exceptionreport" />,
  title: "Exception Reports",
  subMenu: [
    {
      key: "Late check-in",
      path: "Late-check-in",
      title: "Late check-in",
      icon: <Icon name="latecheckin" />,
    },
    {
      key: "Early check-out",
      path: "Early-check-out",
      title: "Early check-out",
      icon: <Icon name="earlycheckout" />,
    },
    {
      key: "Absent report",
      path: "Absent-report",
      title: "Absent report",
      icon: <Icon name="absentreport" />,
    },
  ],
};

const employeeMenu = {
  key: "employee",
  icon: <Icon name="employee" />,
  path: "employee",
  title: "Employee",
};

const departmentMenu = {
  key: "department",
  icon: <Icon name="department" />,
  path: "department",
  title: "Department",
};

const roleMenu = {
  key: "role",
  icon: <Icon name="role" />,
  path: "roles",
  title: "Role",
};
const shiftMenu = {
  key: "shift",
  icon: <Icon name="shift" />,
  path: "shift",
  title: "Shift",
};

export const HR_MENU = [
  dashboardMenu,
  approvalMenu,
  calendarMenu,
  teamMenu,
  reportMenu,
  ExceptionReport,
];

export const CEO_MENU = [
  dashboardMenu,
  approvalMenu,
  calendarMenu,
  teamMenu,
  reportMenu,
  ExceptionReport,
];

export const HOD_MENU = [
  dashboardMenu,
  approvalMenu,
  calendarMenu,
  teamMenu,
  taskMenu,
  reportMenu,
  ExceptionReport,
];

export const STAFF_MENU = [
  dashboardMenu,
  myCalendarMenu,
  myTeamMenu,
  myDocumentMenu,
  myTaskMenu,
  myLeaveReport,
];

export const ADMIN = [
  adminDashboardMenu,
  departmentMenu,
  locationMenu,
  employeeMenu,
  roleMenu,
  shiftMenu,
];

export const MENU = {
  1: HR_MENU,
  2: HOD_MENU,
  3: HOD_MENU,
  4: STAFF_MENU,
  5: ADMIN,
  6: CEO_MENU,
};

export const STATUS_CODE = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  INVALID_PAYLOAD: "INVALID_PAYLOAD",
  AUTHENTICATION_FAILED: "AUTHENTICATION_FAILED",
  AUTHENTICATION_SUCCESSFUL: "AUTHENTICATION_SUCCESSFUL",
  USER_DISABLED: "USER_DISABLED",
  VALID_SESSION: "VALID_SESSION",
  SAME_PASSWORD: "SAME_PASSWORD",
  INVALID_CODE: "INVALID_CODE",
  RECORD_NOT_FOUND: "RECORD_NOT_FOUND",
  INVALID_SESSION: "INVALID_SESSION",
  PASSWORD_UPDATED: "PASSWORD_UPDATED",
  RECORD_EXIST: "RECORD_EXIST",
  OUTSIDE_ALLOWED_PROXIMITY: "OUTSIDE_ALLOWED_PROXIMITY",
  PUNCH_IN_SUCCESSFUL: "PUNCH_IN_SUCCESSFUL",
  PUNCH_OUT_SUCCESSFUL: "PUNCH_OUT_SUCCESSFUL",
  BREAK_STARTED: "BREAK_STARTED",
  BREAK_ENDED: "BREAK_ENDED",
  SOMETHING_WENT_WRONG: "SOMETHING_WENT_WRONG",
  TASK_SAVED: "TASK_SAVED",
  TASK_DELETED: "TASK_DELETED",
  LEAVE_EXCEED: "LEAVE_EXCEED",
  ANNUAL_LEAVE_AVAILABLE: "ANNUAL_LEAVE_AVAILABLE",
  SICK_LEAVE_AVAILABLE: "SICK_LEAVE_AVAILABLE",
  REASON: "SEND_REASON",
};

export const LEAVE_INFO = {
  AVAILABLE: "Available",
  CONSUMED: "Consumed",
};

export const USER_REPORT = {
  ONTIME: "OnTime",
  LATE: "Late",
  LEAVE: "Leave",
  EARLYEXIT: "Early Exit",
  ABSENT: "Absent",
};

export const LEAVE_ICON = {
  7: "calendar2",
  1: "medical_box",
  2: "medical_box",
  3: "medical_box",
  5: "pregnant_woman",
  6: "baby_carrier",
};

export const REPORT_DURATION_TYPES = [
  { value: 2, label: "Monthly Report" },
  { value: 1, label: "Custom Date" },
];

export const REPORT_DURATION_TYPES1 = [{ value: 2, label: "Monthly Report" }];

export const APPROVAL_FILTER_TYPES = [
  { value: 4, label: "All" },
  { value: 1, label: "Custom Date" },
  { value: 2, label: "Monthly" },
];

export const CHECKIN_REPORT_FILTER_TYPES = [
  { value: 1, label: "Custom Date" },
  { value: 2, label: "Monthly" },
];
export const MONTH_LIST = [
  { index: 1, value: "January", label: "January" },
  { index: 2, value: "February", label: "February" },
  { index: 3, value: "March", label: "March" },
  { index: 4, value: "April", label: "April" },
  { index: 5, value: "May", label: "May" },
  { index: 6, value: "June", label: "June" },
  { index: 7, value: "July", label: "July" },
  { index: 8, value: "August", label: "August" },
  { index: 9, value: "September", label: "September" },
  { index: 10, value: "October", label: "October" },
  { index: 11, value: "November", label: "November" },
  { index: 12, value: "December", label: "December" },
];

export const WEB_SOCKET_CONNECTION_STATUS = {
  [ReadyState.CONNECTING]: "Connecting",
  [ReadyState.OPEN]: "Open",
  [ReadyState.CLOSING]: "Closing",
  [ReadyState.CLOSED]: "Closed",
  [ReadyState.UNINSTANTIATED]: "Uninstantiated",
};

export const importantPeople = [
  "sandeep.khapre@bdo-ea.com",
  "george.ndungu@bdo-ea.com",
  "priscillah.githanga@bdo-ea.com",
];

export const countryList = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of The",
  "Cook Islands",
  "Costa Rica",
  "Cote D'ivoire",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-bissau",
  "Guyana",
  "Haiti",
  "Heard Island and Mcdonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and The Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and The South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Eswatini",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan (ROC)",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const CompetencyQC = [
  "Audit & Assurance",
  "Tax",
  "Advisory/Consulting",
  "BSO",
];

export const SUPERVISOR = [
  "Sajan SHAH",
  "Zahir SHEIKH",
  "Collins OKANIA",
  "Moses KINYANJUI",
  "Peris MACHARIA",
  "Bryan NGANGA",
  "Peter GITHONGO",
  "Mathew MUMO",
  "Justin WAMBUA",
  "Robert NDUNGU",
  "Nehemia AKONG'O",
  "Kennedy WANDAHI",
  "Fredrick GOGA",
  "Eliud NJOROGE",
  "John GITAHI",
  "Robert MWAURA",
  "Sandeep KHAPRE",
  "Clifford AH CHIP",
  "Magdalene MATHENDU",
  "Justus MOKUA",
  "Steve OKOTH",
  "John KOMU",
  "Daniel MULWA",
  "Susan NYOIKE",
  "Henry SANG",
  "Joseph MWANZANJE",
  "Priscillah GITHANG'A",
  "Roger MUHIA",
  "Maurine KIMNYANGO",
  "Peter MWANGI",
  "Angelique Kantengwa",
  "George Ndungu",
  "Vikash Bunjun",
  "Abel Seid",
  "Agegnehu Tesfa",
  "Alex Kisakye",
  "Alexis Mirimo",
  "Ali Selemani",
  "Amanda Dorothy Nalunkuma",
  "Anna Kakuba",
  "Asterius Rwehikiza",
  "Augustin Muberamfura",
  "Beatrice Mujawayezu",
  "Brenda Ayebare",
  "Catherine Njogu",
  "Cedrick Kibalama",
  "Christopher Kintu Ssenono",
  "Clement Niyitegeka Kabano Egide",
  "Daniel Ishimwe",
  "Duncan Kayiira",
  "Edom Wassie",
  "Emmanuel Habineza",
  "Ephantus Macharia",
  "Eugene Lema",
  "Eunice Mgheni",
  "Fidele Ukwishaka",
  "Francis Ochige",
  "George Otim",
  "George William Ssebalamu",
  "Gerald Bwire",
  "Geta Mehary",
  "Hafiswa Nakanwagi",
  "Harriet Manake",
  "Isaac Kalungi",
  "John Jet Tusabe",
  "Jonathan Fidelis",
  "Judy Mwathi",
  "Juma Buli",
  "Justus Kahumuza",
  "Juvinal Betambira",
  "Kenneth Makanga",
  "Kinoti Roy",
  "Mahuna Mbaga",
  "Marie Rosette Niwewarwego",
  "Martin Kagaba",
  "Mary Nabaggala",
  "Maureen Busingye",
  "Million Kibret",
  "Moses Emuron",
  "Moses Kamande",
  "Moses Lugayizi",
  "Moses Tibamwenda",
  "Mrisho Athumani",
  "Nafkot Fana",
  "Nicholus Mushabenta",
  "Nzioka Muindi",
  "Pascal Ndagijimana",
  "Rose Uroki",
  "Rutta Kabwogi",
  "Samrawit Yemaneh",
  "Seifu Haile",
  "Sheilla Mahoro",
  "Stephen Kihiu",
  "Stephen Muhumuza",
  "Swalehe Madore",
  "Tewahido Samson",
  "Theresa Owomugisha",
  "Tina Belay",
  "Uwase Clarisse",
  "William Nombo",
  "Yusuf Chanyika",
];

export const MANAGER = [
  "Anne MAINA",
  "Benedict OKANIA",
  "Bonareri MOCHAMA",
  "Bryan NGANGA",
  "Collins OKANIA",
  "Daniel MULWA",
  "Eliud NJOROGE",
  "Ephantus MACHARIA",
  "Esther ONYONA",
  "Fredrick GOGA",
  "Irene KENDI",
  "John GITAHI",
  "John KOMU",
  "John NG'ANG'A",
  "Joseph MWANZANJE",
  "Magdalene MATHENDU",
  "Mathew MUMO",
  "Maurine KIMNYANGO",
  "Moses KINYANJUI",
  "Nehemia AKONG'O",
  "Nicholas LANGAT",
  "Paul MACHARIA",
  "Peter KARIUKI",
  "Peter MWANGI",
  "Priscillah GITHANG'A",
  "Robert MWAURA",
  "Roger MUHIA",
  "Sajan SHAH",
  "Soumya VADLAMANI",
  "Stephen MASINGA",
  "Susan GICHURU",
  "Susan NYOIKE",
  "Winnie OTIENO",
];

export const ServiceLine = [
  {
    competency: "Audit & Assurance",
    serviceline: [
      "Audits (IFRS / Statutory Audits)",
      "Non-assurance engagements (AUP + Compilations)",
      "Other assurance engagements (Project Audits)",
      "Reviews (Others)",
    ],
  },
  {
    competency: "Tax",
    serviceline: [
      "Tax advisory Services",
      "Tax Compliance and Assurance Services",
    ],
  },
  {
    competency: "Advisory/Consulting",
    serviceline: [
      "Corporate Finance, Transactions, Restructuring & M&A",
      "Risk Management (RAS), Forensics Investigations, Risk, Regulatory & Compliance",
      "Management Consultancy (Strategy, HR, PM, Devt.Advisory)",
      "Technology Advisory, Cyber security, Analytics & Insights",
    ],
  },
  {
    competency: "BSO",
    serviceline: [
      "Accounting and Payroll Services",
      "Corporate secretarial and administrative services",
      "Business process outsourcing (FMA, BPO and other admin services)",
    ],
  },
];

export const SubServiceLine = [
  "Deal Advisory- Valuation",
  "Deal Advisory- Due Diligence",
  "Deal Advisory- Capital Raise",
  "Deal Advisory- M&A",
  "Risk Advisory- Internal Audit",
  "Risk Advisory- Forensic Services",
  "Risk Advisory- Insolvency",
  "Risk Advisory- Process Consulting",
];

export const CompetencyRole = [
  //? Changed to OfficeCompetencyRole
  "Country Leader- Advisory",
  "Country Leader- Audit",
  "Country Leader- BSO",
  "Country Leader- Data Analyst",
  "Country Leader- Finance",
  "Country Leader- HR",
  "Country Leader- IT",
  "Country Leader- Marketing",
  "Country Leader- Tax",
  "HO- Leader",
  "Managing partner",
  "Member",
  "RCEO",
  "Team Leader- Advisory",
  "Team Leader- Audit",
  "Team Leader- BSO",
  "Team Leader- Tax",
];

export const RegionalCometencyRole = [
  "Backstop",
  "HAAQM",
  "HMR",
  "Regional Leader",
  "Regional Member",
];

export const IndustryRole = [
  "Regional-leader",
  "Country Leader",
  "Office - Leader",
  "Member",
];

export const Grade = [
  "P5",
  "P4",
  "P3",
  "P2",
  "P1",
  "M2",
  "M1",
  "C4",
  "C3",
  "C2",
  "C1",
  "B2",
  "B1",
  "A2",
  "A1",
  "S4",
  "S3",
  "S2",
  "S1",
];

export const CountryCompany = [
  {
    country: "Kenya",
    location: "Nairobi",
  },
  {
    country: "Uganda",
    location: "Kampala",
  },
  {
    country: "Tanzania",
    location: "Dar es Salaam",
  },
  {
    country: "Rwanda",
    location: "Kigali",
  },
  {
    country: "Ethiopia",
    location: "Addis Ababa",
  },
  {
    country: "Burundi",
    location: "Bujumbura",
  },
  {
    country: "DR Congo",
    location: "Kinshasa",
  },
  {
    country: "Head Office",
    location: "Lubumbashi",
  },
  {
    country: "Other",
    location: "Other",
  },
];

export const Degree = [
  "Bachelors - Accounting - Complete",
  "Masters - Economics - Complete",
  "PhD - Accounting - In Progress",
  "MBA - Finance - In Progress",
];

export const Institute = [
  "CPA - Complete",
  "CPA - In progress",
  "CPA - Fellow",
  "etc.",
  "CFA",
  "CISA",
  "CFE",
  "CIA",
  "PMP",
];

export const Greetings = ["Christmas", "Diwali", "Eid", "Chinese NY"];

export const ProfessionalCertification = [
  "CPA - Complete",
  "CPA - In progress",
  "CPA - Fellow",
  "etc.",
  "CFA",
  "CISA",
  "CFE",
  "CIA",
  "PMP",
];

export const Industry = [
  "Agribusiness",
  "Floriculture and Fisheries",
  "Consumer Business (includes Retail, Leisure & Hospitality & Gaming)",
  "Financial Services",
  "Not for Profit",
  "Government & Public Sector",
  "Infrastructure",
  "Real Estate & Construction",
  "Transport & Logistics",
  "Manufacturing & Distributions",
  "Banking",
  "Technologies",
  "Media and Telecom",
  "Natural Resources & Energy",
];

// export const Industry = [
//   "Agribusiness",
//   "Floriculture and Fisheries",
//   "Government and Public Sector",
//   "Consumer Business (includes Retail, Leisure & Hospitality & Gaming)",
//   "Infrastructure",
//   "Real Estate and Construction Transport and Logistics",
//   "Banking",
//   "Insurance and other Financial Services",
//   "Manufacturing & Distributions",
//   "Technologies",
//   "Media and Telecom",
//   "Not for Profit",
//   "Natural Resources & Energy",
// ];

export const Sector = [
  "Tea, Coffee, Wheat, Barley, Tobacco and other cash crops",
  "Entertainment",
  "Banks",
  "Textile and EPZ Companies",
  "NGOs",
  "Renewable energy",
  "Government ministries",
  "IT hardware and software companies",
  "Real Estate Development",
];

export const secondaryIndustry = [
  "Agribusiness, Floriculture and Fisheries ",
  "Consumer Business (includes Retail, Leisure & Hospitality & Gaming)",
  "Banking, Insurance and other financial services",
  "Manufacturing & Distributions",
  "Not for Profit",
  "Natural Resources & Energy",
  "Government and Public sector",
  "Technologies, Media and Telecom",
  "Infrastructure, Real estate & construction Transport and Logistics",
];

export const ContractingCompany = [
  "BDO Advisory Services Limited",
  "BDO Consulting PLC",
  "BDO Africa Holdings Ltd",
  "BDO EA (Rwanda) Ltd",
  "BDO Kenya Limited",
  "DCDM Advisory Services Limited",
];

export const DOCUMENTFOLDER = [
  { label: "Identity Information", value: "identityinformation" },
  { label: "Employment Contract", value: "employmentcontract" },
  { label: "PIP", value: "PIP" },
  { label: "Warnings", value: "warnings" },
  { label: "Commendation Letters", value: "commendationletters" },
  { label: "Performance Contract", value: "performancecontract" },
  { label: "Performance Appraisal", value: "performanceappraisal" },
  { label: "Wow Factor evidence", value: "factorevidence" },
  { label: "Star note", value: "starnote" },
  { label: "CV", value: "cv" },
];

function lightenColor(color, percent = 50) {
  const num = parseInt(color.slice(1), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const G = ((num >> 8) & 0x00ff) + amt;
  const B = (num & 0x0000ff) + amt;

  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
    (B < 255 ? (B < 1 ? 0 : B) : 255)
  )
    .toString(16)
    .slice(1)}`;
}

export const getColor = (index) => {
  const colors = [
    { actual: "#DC143C", light: lightenColor("#DC143C", 30) },
    { actual: "#00CED1", light: lightenColor("#00CED1", 30) },
    { actual: "#9370DB", light: lightenColor("#9370DB", 30) },
    { actual: "#32CD32", light: lightenColor("#32CD32", 30) },
    { actual: "#FF6347", light: lightenColor("#FF6347", 30) },
    { actual: "#0F52BA", light: lightenColor("#0F52BA", 30) },
    { actual: "#FFBF00", light: lightenColor("#FFBF00", 30) },
    { actual: "#4B0082", light: lightenColor("#4B0082", 30) },
    { actual: "#B76E79", light: lightenColor("#B76E79", 30) },
    { actual: "#008080", light: lightenColor("#008080", 30) },
    { actual: "#FF00FF", light: lightenColor("#FF00FF", 30) },
    { actual: "#CCCCFF", light: lightenColor("#CCCCFF", 10) },
    { actual: "#98FF98", light: lightenColor("#98FF98", 30) },
    { actual: "#FF4500", light: lightenColor("#FF4500", 30) },
    { actual: "#4169E1", light: lightenColor("#4169E1", 30) },
    { actual: "#FFDAB9", light: lightenColor("#FFDAB9", 10) },
    { actual: "#8A2BE2", light: lightenColor("#8A2BE2", 30) },
    { actual: "#EAA221", light: lightenColor("#EAA221", 30) },
    { actual: "#7FFFD4", light: lightenColor("#7FFFD4", 30) },
    { actual: "#7FFF00", light: lightenColor("#7FFF00", 30) },
  ];

  return colors[index % colors.length];
};

export const TOPGRADESUPERVISORLIST = ["P5", "P4", "P3", "P2", "P1"];

export const getAllSubordinates = (data, supervisorId) => {
  let result = [];
  let visited = new Set();
  let memo = new Map();

  function findSubordinates(supervisorId) {
    if (visited.has(supervisorId)) {
      return;
    }
    if (memo.has(supervisorId)) {
      result = result.concat(memo.get(supervisorId));
      return;
    }

    visited.add(supervisorId);
    let subordinates = [];

    data.forEach((employee) => {
      if (employee.OfficeSupervisorId === supervisorId) {
        subordinates.push(employee.employeeid);
        findSubordinates(employee.employeeid);
      }
    });

    result = result.concat(subordinates);
    memo.set(supervisorId, subordinates);
  }
  result.push(supervisorId);
  findSubordinates(supervisorId);
  return result;
};

export const Level2Users = [
  "clifford.ahchip@bdo-ea.com",
  "million.kibret@bdo-ea.com",
  "kenneth.makanga@bdo-ea.com",
  "yusuf.chanyika@bdo-ea.co.tz",
  "emmanuel.habineza@bdo-ea.com",
  "emmanuel.habineza@bdo-ea.com",
  "akash@gmail.com",
];

export const RelationShipOption = ["Admin", "Client Onboarding"];

export const RiskOption = ["Very Low", "Low", "Medium", "High", "Very High"];

export const IncorporationData = [
  "Sole Proprietorship",
  "General Partnership",
  "Local Limited Liab Company",
  "Listed Entity",
  "Branch of Regional/MNC",
  "Offshore Holding Company",
  "Non-profit Organisation",
  "Paratsatal/Government Department",
  "Other - Corporation",
  "Co - operation",
  "Local Ltd Liability Co.",
  "Creadit Union",
  "Fund",
  "Government Organisation",
  "Induvidual",
  "Limited Partnership",
  "Private Corporation",
  "Public Corporation",
  "Trust",
];

export const ManagingOffice = [
  "BDO East Africa",
  "BDO EA Uganda",
  "BDO EA Kenya",
  "BDO EA Ethiopia",
  "BDO EA Tanzania",
  "BDO EA Rwanda",
];

export const ClientStatus = ["Active", "Prospect", "Inactive", "Left Clients"];

export const IndustryOption = [
  "NA",
  "Government and Public sector",
  "Consumer Business (includes Retail,Leisure & Hospitality & Gaming)",
  "Agribusiness, Floriculture and Fisheries",
  "Natural Resources & Energy",
  "Manufacturing & Distributions",
  "Not for Profit",
  "Technologies,Media and Telecom",
  "Infrastructure,Real estate & construction Transport and Logistics",
  "Banking,Insurance and other financial services",
];

export const PeriodOptions = ["First Half", "Second Half", "Full Year"];

export const accessRoleOptions = [
  { value: "isauditor", label: "IS Auditor" },
  { value: "member", label: "Member" },
  // { value: "staff", label: "Staff" },
];

export const RCEO = [
  [
    {
      id: 1,
      type: "checkbox",
      label:
        "Regional consolidation of Annual strategy plan formulation + Budgets – Revenue by service lines, leaders, industry, geography etc + Payroll (incl staff list + Bonus), Cost Optimisation, Profits & Distribution.",
      name: "regionalConsolidation",
    },
  ],
  [
    {
      id: 2,
      type: "checkbox",
      label: "ILP and Office Coordination",
      name: "regionalConsolidation1",
    },
  ],
  [
    {
      id: 3,
      type: "checkbox",
      label:
        "Leadership and membership of Institutes (leadership in the sub-committees/ board), Clubs (French Business, Golf), Associations (Venture Capital, TiE/ Start up incubators/ angel investors), Social/ digital networks (as individuals and as company)",
      name: "regionalConsolidation2",
    },
  ],
  [
    {
      id: 4,
      type: "checkbox",
      label:
        "Provide the link between strategic leadership and operational command by chairing EXCO meetings, and managing the outcomes from such meetings",
      name: "regionalConsolidation3",
    },
  ],
  [
    {
      id: 5,
      type: "checkbox",
      label:
        "Lead BDO by inspiring and motivating Partners and Directors and staff to live the BDO values whilst building a sustainable and profitable business",
      name: "regionalConsolidation4",
    },
  ],
  [
    {
      id: 6,
      type: "checkbox",
      label:
        "Overseeing overall Firm direction, including future direction, transition from operating on Regional Office to National Business Line basis, succession planning, etc.",
      name: "regionalConsolidation5",
    },
  ],
  [
    {
      id: 7,
      type: "checkbox",
      label:
        "Collaborate with EXCO to define and articulate the vision and mission of BDO and develop strategy proposals for recommendation to EXCO and ensure that agreed corporate strategy actions are reflected in the business and support the vision and the mission.",
      name: "regionalConsolidation6",
    },
  ],
  [
    {
      id: 8,
      type: "checkbox",
      label:
        "Meet the Heads of Business Lines and MPs individually and as a group to ensure that the overall BDO strategy is understood and implemented consistently",
      name: "regionalConsolidation7",
    },
  ],
  [
    {
      id: 9,
      type: "checkbox",
      label:
        "establish an environment that supports the system of quality management, demonstrating a commitment to quality through a culture that exists throughout the Cluster.",
      name: "regionalConsolidation8",
    },
  ],
  [
    {
      id: 10,
      type: "checkbox",
      label: "BDO Global and BDO offices coordination",
      name: "regionalConsolidation9",
    },
  ],
  [
    {
      id: 11,
      type: "checkbox",
      label:
        "Member Firm management (MFR) - Structure (no. of signing partners), Ownership (Secretarial Files), Partnership/ Shareholder Agreement, Succession Planning Strategy, Contract between Partnership and LLC for Swiping money and dividend payment",
      name: "regionalConsolidation10",
    },
  ],
  [
    {
      id: 12,
      type: "checkbox",
      label:
        "Regional initiatives for Leadership in Markets, through completeness in services and Innovation and becoming advisers of the future to provide exceptional client service",
      name: "regionalConsolidation11",
    },
  ],
  [
    {
      id: 13,
      type: "checkbox",
      label: "ILP and Office Coordination",
      name: "regionalConsolidation12",
    },
  ],
  [
    {
      id: 14,
      type: "checkbox",
      label:
        "Fulfill the roles and responsibilities as set out in the Terms of Reference issued by BDO internationally.",
      name: "regionalConsolidation13",
    },
  ],
];

export const MANAGERKEYRES = [
  [
    {
      id: 1,
      label: "Provide leadership on assignments.",
      type: "checkbox",
      name: "Leadership1",
    },
  ],
  [
    {
      id: 2,
      label:
        "Align the team to focus effort and build in commitment towards achieving set goals and objectives.",
      type: "checkbox",
      name: "Leadership2",
    },
  ],
  [
    {
      id: 3,
      label:
        "Monitor team performance and communicate effectively to ensure consistency with BDO quality standards and engagement approach.",
      type: "checkbox",
      name: "Leadership3",
    },
  ],
  [
    {
      id: 4,
      label:
        "Create an environment in which the team is empowered to continuously improve client service and work processes.",
      type: "checkbox",
      name: "Leadership4",
    },
  ],
  [
    {
      id: 5,
      label:
        "Manage staff recruitment process to ensure hiring of high quality staff.",
      type: "checkbox",
      name: "Leadership5",
    },
  ],
  [
    {
      id: 6,
      label:
        "Manage performance appraisal process, conduct timely performance reviews consistent with the firm’s quality standards, make recommendations for promotions.",
      type: "checkbox",
      name: "Leadership6",
    },
  ],
  [
    {
      id: 7,
      label:
        "Identify staff members requiring development, identify training needs and recommend suitable training.",
      type: "checkbox",
      name: "Leadership7",
    },
  ],
  [
    {
      id: 8,
      label:
        "Foster effective teamwork and empower staff to allow them to achieve engagement and personal performance objectives.",
      type: "checkbox",
      name: "Leadership8",
    },
  ],
  [
    {
      id: 9,
      label:
        "Build new relationships and maintain existing relationships with BDO professionals in other lines of business.",
      type: "checkbox",
      name: "Leadership9",
    },
  ],
  [
    {
      id: 10,
      label: "Manage, motivate and provide guidance to staff.",
      type: "checkbox",
      name: "Leadership10",
    },
  ],
];

export const MPS = [
  [
    {
      id: 1,
      label:
        "Build a cohesive team by promoting strong teamwork amongst the Partners, Directors and staff of the various Business Lines within the country Office",
      type: "checkbox",
      name: "MPS",
    },
  ],
  [
    {
      id: 2,
      label: "Inspire, encourage and motivate Partners, Directors and staff",
      type: "checkbox",
      name: "MPS1",
    },
  ],
  [
    {
      id: 3,
      label:
        "Country consolidation of Annual strategy plan formulation + Budgets – Revenue by service lines, leaders, industry, geography etc + Payroll (incl staff list + Bonus), Cost Optimisation, Profits & Distribution.",
      type: "checkbox",
      name: "MPS2",
    },
  ],
  [
    {
      id: 4,
      label:
        "Leadership and membership of Institutes (leadership in the sub-committees/ board), Clubs (French Business, Golf), Associations (Venture Capital, TiE/ Start up incubators/ angel investors), Social/ digital networks (as individuals and as company)",
      type: "checkbox",
      name: "MPS3",
    },
  ],
  [
    {
      id: 5,
      label:
        "Organise meetings where the Heads of Business Lines will report on the activities of the quarter and progress made on the implementation of the business plan as well as performance against the annual budget for the country office",
      type: "checkbox",
      name: "MPS4",
    },
  ],
  [
    {
      id: 6,
      label:
        "Keep EXCO informed of current, new or in progress developments in the country. This could be local opportunities or niche markets specific to a country/cluster.",
      type: "checkbox",
      name: "MPS5",
    },
  ],
  [
    {
      id: 7,
      label:
        "establish an environment that supports the system of quality management, demonstrating a commitment to quality through a culture that exists throughout the firm.",
      type: "checkbox",
      name: "MPS6",
    },
  ],
  [
    {
      id: 8,
      label:
        "Member Firm management (MFR) - Structure (no. of signing partners), Ownership (Secretarial Files), Partnership/ Shareholder Agreement, Succession Planning Strategy, Contract between Partnership and LLC for Swiping money and dividend payment",
      type: "checkbox",
      name: "MPS7",
    },
  ],
];

export const currencyOptions = [
  { label: "Kenyan Shilling", value: "kenyanShilling" },
  { label: "Ugandan Shilling", value: "ugandanShilling" },
  { label: "Tanzanian Shilling", value: "tanzanianShilling" },
  { label: "Ethiopian Birr", value: "ethiopianBirr" },
  { label: "Rwandan Franc", value: "rwandanFranc" },
  { label: "Mauritius Rupee", value: "mauritiusRupee" },
  { label: "Euros", value: "euros" },
  { label: "US Dollar", value: "usDollar" },
  { label: "Pound Sterling", value: "poundSterling" },
];



export const PartnerDirecter = [
  [
    {
      id: 1,
      label:
        "Build and maintain a good relationship with all Partners and Directors, across all offices and Business Lines",
      type: "checkbox",
      name: "partner",
    },
  ],
  [
    {
      id: 2,
      label:
        "Be the driving force behind the implementation of the agreed annual business plan and strategy; Continually monitor implementation progress against the agreed annual business plan and strategy",
      type: "checkbox",
      name: "partner1",
    },
  ],
  [
    {
      id: 3,
      label:
        "Report to the Partners and Directors in each Regional Office on the activities of the quarter and progress made on the implementation of the business plan as well as performance against the annual budget",
      type: "checkbox",
      name: "partner2",
    },
  ],
  [
    {
      id: 4,
      label:
        "Generate a vision, establish direction, and align people to focus effort and build in commitment towards achieving the goals of the team",
      type: "checkbox",
      name: "partner3",
    },
  ],
  [
    {
      id: 5,
      label:
        "Create an environment in which the team is empowered to continuously improve client service and work processes",
      type: "checkbox",
      name: "partner4",
    },
  ],
  [
    {
      id: 6,
      label:
        "Planning the Firm’s business line in detail and to allocate staff responsibilities for the work involved in order to ensure that client deadlines are met",
      type: "checkbox",
      name: "partner5",
    },
  ],
  [
    {
      id: 7,
      label:
        "Act as the Firm’s Centre of Excellence in relation to service line matters and to keep himself / herself apprised of all matters relating to the service line",
      type: "checkbox",
      name: "partner6",
    },
  ],
  [
    {
      id: 8,
      label:
        "Build a cohesive team by promoting strong teamwork within his/her Business Line, and across Business Lines, providing consistent seamless performance on engagements",
      type: "checkbox",
      name: "partner7",
    },
  ],
  [
    {
      id: 9,
      label:
        "Review the quality and number of department staff and make recommendations to the Managing Partner in relation to recruitment, training and performance management, promotions and identify staff members requiring development",
      type: "checkbox",
      name: "partner8",
    },
  ],
  [
    {
      id: 10,
      label:
        "Counsel and mentor staff members within the Service line / team in relation to technical and work management matters",
      type: "checkbox",
      name: "partner9",
    },
  ],
  [
    {
      id: 11,
      label:
        "Plan, coordinate and conduct both internal and external training necessary to develop and maintain the competence of staff",
      type: "checkbox",
      name: "partner10",
    },
  ],
  [
    {
      id: 12,
      label:
        "Identify potential future leaders and ensure plans are in place to develop these candidates",
      type: "checkbox",
      name: "partner11",
    },
  ],
];

export const Seniors = [
  [
    {
      id: 1,
      label: "Provide leadership on assignments",
      type: "checkbox",
      name: "Seniors1",
    },
  ],
  [
    {
      id: 2,
      label:
        "Develop teams through effectively delegating tasks and providing guidance to junior staff",
      type: "checkbox",
      name: "Seniors2",
    },
  ],
  [
    {
      id: 3,
      label:
        "Share knowledge with team members through best practices and on-the-job training",
      type: "checkbox",
      name: "Seniors3",
    },
  ],
  [
    {
      id: 4,
      label:
        "Build new relationships and maintain existing relationships with BDO professionals in other lines of business",
      type: "checkbox",
      name: "Seniors4",
    },
  ],
  [
    {
      id: 5,
      label:
        "Manage fieldwork, inform supervisors/managers of the engagement status, and guide staff in delivering on assignment requirements",
      type: "checkbox",
      name: "Seniors5",
    },
  ],
  [
    {
      id: 6,
      label:
        "Foster an efficient, innovative, and team-oriented work environment",
      type: "checkbox",
      name: "Seniors6",
    },
  ],
];

export const QCAssistantAssociates = [
  [
    {
      id: 1,
      label: "Take on challenges and see assigned tasks to completion",
      type: "checkbox",
      name: "taskCompletion",
    },
  ],
  [
    {
      id: 2,
      label: "Build new relationships with other staff across the firm",
      type: "checkbox",
      name: "relationshipBuilding",
    },
  ],
  [
    {
      id: 3,
      label:
        "Communicate ideas/opinions and information to the team and listen/respond to other team members’ views",
      type: "checkbox",
      name: "communication",
    },
  ],
  [
    {
      id: 4,
      label:
        "Accept constructive feedback given by peers and superiors and use it to guide future actions",
      type: "checkbox",
      name: "feedbackAcceptance",
    },
  ],
];

export const QCmanager = [
  [
    {
      id: 1,
      label: "Provide leadership to the team",
      type: "checkbox",
      name: "leadership",
    },
  ],
  [
    {
      id: 2,
      label:
        "Align the team to focus effort and build in commitment towards achieving set goals and objectives",
      type: "checkbox",
      name: "alignmentAndCommitment",
    },
  ],
  [
    {
      id: 3,
      label:
        "Monitor team performance and communicate effectively to ensure consistency with BDO quality standards",
      type: "checkbox",
      name: "performanceMonitoring",
    },
  ],
  [
    {
      id: 4,
      label:
        "Create an environment in which the team is empowered to continuously improve client service and work processes",
      type: "checkbox",
      name: "empowermentAndImprovement",
    },
  ],
  [
    {
      id: 5,
      label:
        "Manage staff recruitment process to ensure hiring of high-quality staff",
      type: "checkbox",
      name: "staffRecruitment",
    },
  ],
  [
    {
      id: 6,
      label:
        "Manage performance appraisal process, conduct timely performance reviews consistent with the firm’s quality standards, make recommendations for promotions",
      type: "checkbox",
      name: "performanceAppraisal",
    },
  ],
  [
    {
      id: 7,
      label:
        "Identify staff members requiring development, identify training needs, and recommend suitable training",
      type: "checkbox",
      name: "staffDevelopment",
    },
  ],
  [
    {
      id: 8,
      label:
        "Foster effective teamwork and empower staff to achieve engagement and personal performance objectives",
      type: "checkbox",
      name: "teamworkAndEmpowerment",
    },
  ],
  [
    {
      id: 9,
      label:
        "Build new relationships and maintain existing relationships with BDO professionals in other lines of business",
      type: "checkbox",
      name: "relationshipBuilding",
    },
  ],
  [
    {
      id: 10,
      label: "Manage, motivate, and provide guidance to staff",
      type: "checkbox",
      name: "staffManagementAndGuidance",
    },
  ],
];

export const HRAssistant = [
  [
    {
      id: 1,
      label: "Take on challenges and see assigned tasks to completion",
      type: "checkbox",
      name: "taskCompletion",
    },
  ],
  [
    {
      id: 2,
      label: "Build new relationships with other staff across the firm",
      type: "checkbox",
      name: "relationshipBuildingAcrossFirm",
    },
  ],
  [
    {
      id: 3,
      label:
        "Communicate ideas/opinions and information to the team and listen/respond to other team members' views",
      type: "checkbox",
      name: "communication",
    },
  ],
  [
    {
      id: 4,
      label:
        "Accept constructive feedback given by peers and superiors and use it to guide future actions",
      type: "checkbox",
      name: "feedbackAcceptance",
    },
  ],
];

export const NoDataKey = [
  [
    {
      id: 1,
      label: "No Data",
      type: "checkbox",
      name: "noData",
    },
  ],
];

export const RelationshipType = [
  { id: 8, value: "Prospect" },
  { id: 3, value: "Customer" },
  { id: 100000013, value: "teacher training colleges" },
  { id: 100000012, value: "PVT-6LUKVZMB" },
  { id: 100000011, value: "Proposal stage" },
  { id: 100000010, value: "Peris Macharia" },
  { id: 100000009, value: "P000634725S" },
  { id: 100000008, value: "OP.218/051/98183/1283" },
  { id: 100000007, value: "Off Muchai Drive" },
  { id: 100000006, value: "Nairobi" },
  { id: 100000005, value: "Kenya" },
  { id: 100000004, value: "Don Bosco Embu" },
  { id: 100000003, value: "City Square" },
  { id: 100000002, value: "2018 / 614275 / 08" },
  { id: 100000001, value: '"Dagoretti' },
  { id: 100000000, value: '" Dagoretti' },
  { id: 12, value: "Other" },
  { id: 11, value: "Vendor" },
  { id: 10, value: "Supplier" },
  { id: 9, value: "Reseller" },
  { id: 7, value: "Press" },
  { id: 6, value: "Influencer" },
  { id: 5, value: "Partner" },
  { id: 4, value: "Investor" },
  { id: 2, value: "Consultant" },
  { id: 1, value: "Competitor" },
];

export const billingOffice = [
  { id: 1, name: "BDO Ethiopia Audit", tax: 0.15 },
  { id: 2, name: "BDO Consulting PLC - Ethiopia", tax: 0.15 },
  { id: 3, name: "BDO EA, Ethiopia", tax: 0.15 },
  { id: 4, name: "BDO East Africa Kenya", tax: 0.16 },
  { id: 5, name: "BDO Kenya Limited", tax: 0.16 },
  { id: 6, name: "DCDM Advisory Services Ltd (Kenya)", tax: 0.16 },
  { id: 7, name: "DCDM Advisory Services Uganda Limited - Uganda", tax: 0.18 },
  { id: 8, name: "DCDM Consulting Limited - Tanzania", tax: 0.18 },
  { id: 9, name: "BDO Corporate Advisory Rwanda Ltd", tax: 0.18 },
  { id: 10, name: "BDO East Africa, Uganda", tax: 0.18 },
  { id: 11, name: "BDO East Africa, Tanzania", tax: 0.18 },
  { id: 12, name: "BDO EA Rwanda Ltd", tax: 0.18 },
  { id: 13, name: "BDO DR Congo SASU", tax: 0.16 },
  { id: 14, name: "BDO DR Congo AUDIT SARL", tax: 0.16 },
  { id: 15, name: "BDO East Africa Advisory Service Ltd UG", tax: 0.18 },
  { id: 16, name: "BDO Uganda Consolidated", tax: 0.18 },
  { id: 17, name: "DCDM Advisory Services Limited UG", tax: 0.18 },
  { id:18,  name:"BDO Africa Holdings Ltd", tax:0.18},
  {id:19, name:"BDO Advisory services Ltd", tax: 0.18}
];

export const CountryTimezone = [
  {country: "DR Congo", Timezone: "Africa/Kinshasa"},
  {country: "Kenya", Timezone: "Africa/Nairobi"},
  {country: "Uganda", Timezone: "Africa/Kampala"},
  {country: "Rwanda", Timezone: "Africa/Kigali"},
  {country: "Ethiopia", Timezone: "Africa/Addis_Ababa"},
  {country: "Tanzania", Timezone: "Africa/Dodoma"},
  {country: "BDO EAST AFRICA", Timezone: "Africa/Nairobi"}, // Assuming this follows the same timezone as Kenya
  {country: "Burundi", Timezone: "Africa/Bujumbura"},
  {country: "Head Office", Timezone: "Africa/Nairobi"}
];