import { Col, DatePicker, Form, Row } from "antd";
import Table from "atoms/Table";
import Title from "atoms/Title";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";
import queryKeys from "common/queryKey";
import { useScrollFetch } from "hooks/useScrollFetch";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setFilter } from "redux/actions";
import {
  getClientid,
  getFilters,
  getManagingOffice,
  getUserInfo,
  getUserList,
} from "redux/selectors";
import { ButtonOutlined } from "reusableComponent/UIButtons";
import { utils, writeFileXLSX } from "xlsx";

function IndependenceAnalysis() {
  const userList = useSelector((state) => getUserList(state));
  const [payload, setPayload] = useState({});
  const clientId = useSelector(getClientid);
  const managingOfficeList = useSelector(getManagingOffice);
  const history = useHistory();
  const userInfo = useSelector(getUserInfo);
  const finduser = userList?.find((item) => item?.userid === userInfo?.userid);
  const columns = [
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
      className: "normal-column ",
      render: (val) => {
        return <span className="text-primary">{val}</span>;
      },
      onCell: (record) => ({
        onClick: () => {
          if (record?.IndependenceForm) {
            history.push(`/independence-analysis/${record.userid}`);
          }
        },
        style: { cursor: "pointer" },
      }),
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      className: "normal-column",
    },

    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
      className: "normal-column",
      ellipsis: {
        showTitle: true,
      },
      render: (val) => {
        return <span>{val === "null" || val === "" ? "-" : val}</span>;
      }
    },
    {
      title: "Email",
      dataIndex: "userid",
      key: "person_email_address",
      className: "normal-column",
      render: (val) => {
        return <span>{val === "null" || val === "" ? "-" : val}</span>;
      },
    },
    {
      title: "Submission Status",
      dataIndex: "independenceform",
      key: "IndependenceForm",
      className: "normal-column",
      render: (val) => {
        return <span>{val === "yes" ? "Yes" : "No"}</span>;
      },
    },
    {
      title: "Date Submitted",
      dataIndex: "time",
      key: "declarationSubmittedDate",
      className: "normal-column",
      render: (val) => {
        return <span>{val && val !== undefined && val !== null && val !== "null" ? moment(val).format("DD-MM-YYYY") : "-"}</span>;
      }
    },
  ];

  if (finduser?.managingoffice[0] === "Head Office") {
    columns.push({
      title: "Managing Office",
        dataIndex: "managingoffice",
        key: "managingoffice",
        className: "normal-column",
        render: (val, record) => {
          return (
            <span>
              {record?.managingoffice ? record?.managingoffice : "-"}
            </span>
          );
        },
    });
  }

  const [form] = Form.useForm();

  const filters = useSelector(getFilters);
  const menuName = "alldeclaration";
  const initialFilter = filters[menuName];

  const dispatch = useDispatch();

  const handleFormChange = () => {
    const values = form.getFieldsValue();
    dispatch(setFilter(menuName, "name", values.name || []));
    dispatch(
      setFilter(menuName, "managingoffice", values.managingoffice || [])
    );
    dispatch(setFilter(menuName, "status", values.status || []));
    // const name = form.getFieldValue("name") || [];
    // const managingoffice = form.getFieldValue("managingoffice") || [];
    // const status = form.getFieldValue("status") || [];

    // setPayload({
    //   userid: name,
    //   managingoffice: managingoffice,
    //   IndependenceForm: status,
    // });
  };

  // const getFilteredData = () => {
  //   const name = form.getFieldValue("name") || [];
  //   const managingoffice = form.getFieldValue("managingoffice") || [];
  //   const status = form.getFieldValue("status") || [];

  //   setPayload({
  //     userid: name,
  //     managingoffice: managingoffice,
  //     IndependenceForm: status
  //   })

  // };

  const { getAllDeclaration } = queryKeys;

  const { key, url } = getAllDeclaration(
    clientId,
    filters?.alldeclaration?.name ?? []
  );

  const { data, lastElementRef, isLoading, refetch, isReloading } =
    useScrollFetch(key, {
      endPoint: url,
      queryParam: "LastEvaluatedKey",
      extra: {
        userid: filters?.alldeclaration?.name ?? [],
        managingoffice: finduser?.managingoffice[0]
      },
    });

  const dataList = useMemo(() => {
    if (data) {
      return data?.pages?.reduce((acc, page) => {
        if (page?.response.data === undefined) return acc;
        return [...acc, ...page?.response.data];
      }, []);
    } else {
      return [];
    }
  }, [data]);

  // useEffect(() => {
  //   refetch()
  // }, [payload])

  const userOptions = userList?.map((user) => {
    return {
      label: user?.fullname,
      value: user?.userid,
    };
  });

  const managingOfficeOption = managingOfficeList?.map((val) => {
    return {
      label: val,
      value: val,
    };
  });

  const statusOptions = [
    {
      label: "Submitted",
      value: "yes",
    },
    {
      label: "Not Submitted",
      value: "no",
    },
  ];

  const getFilteredData = () => {
    const name = form.getFieldValue("name") || [];

    const country = form.getFieldValue("managingoffice") || [];
    const status = form.getFieldValue("status") || [];
    const year = form.getFieldValue("year") || "";

    if (
      name.length > 0 ||
      status.length > 0 ||
      country.length > 0 ||
      year !== ""
    ) {
      return dataList?.filter((item) => {
        const nameMatch =
          name && name.length > 0 ? name.includes(item.userid) : true;
        const statusMatch =
          status && status.length > 0
            ? status.includes(item?.independenceform ?? "no")
            : true;

        const countryMatch =
          country && country.length > 0
            ? country.some((c) => item?.managingoffice?.includes([c])) // Check if any managing office matches
            : true;

        const dateMatch = year?._d
          ? item?.year &&
            moment(item.year).format("YYYY") ===
              moment(year?._d).format("YYYY")
          : // Check if any managing office matches
            true;

        return nameMatch && countryMatch && statusMatch && dateMatch;
      });
    } else {
      return dataList;
    }
  };

  const xport = React.useCallback(() => {
    const elt = document.getElementById("Table");
    const wb = utils.table_to_book(elt);
    writeFileXLSX(wb, "Annual Declaration Report.csv");
  });

  return (
    <div>
      <Row>
        <Col sm={24}>
          <div className="d-flex justify-content-between">
            <Title>Annual Declaration</Title>
            <ButtonOutlined className="text-end" disabled={getFilteredData().length === 0 || null || undefined} onClick={xport}>
              Export CSV file
            </ButtonOutlined>
          </div>
        </Col>
        <Col sm={24}>
          <Form
            form={form}
            layout="vertical"
            className="mt-2"
            onValuesChange={handleFormChange}
            initialValues={{ ...initialFilter }}
          >
            <Row gutter={12} className="g-3 mb-2">
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="name"
                  label="Name"
                  options={userOptions}
                  form={form}
                  handleFormChange={handleFormChange}
                />
              </Col>
              {
                finduser?.managingoffice[0] === "Head Office" ? 
                <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="managingoffice"
                  label="Managing office"
                  options={managingOfficeOption}
                  form={form}
                  handleFormChange={handleFormChange}
                />
              </Col> 
              : ""
              }
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="status"
                  label="Status"
                  options={statusOptions}
                  form={form}
                  handleFormChange={handleFormChange}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <Form.Item name="year">
                  <DatePicker
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "50px",
                      width: "100%",
                    }}
                    picker="year"
                    format={"YYYY"}
                    placeholder="Year"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Table
        dataSource={getFilteredData()}
        columns={columns}
        pagination={false}
        loading={isLoading}
        onRow={(record, index) => ({
          ref: index === dataList?.length - 15 ? lastElementRef : null,
        })}
        id="Table"
      />
    </div>
  );
}

export default IndependenceAnalysis;
